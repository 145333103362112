import Vue from 'vue'
import constantData from '@/helpers/constant'

Vue.filter('formatNumber', function (value) {
  if (!value) return 0

  const thousand = 1000
  const million = 1000000
  const billion = 1000000000
  const trillion = 1000000000000

  var output
  if (!Number.isInteger(value) && value < thousand) {
    return value.toFixed(2)
  }
  if (value < thousand) {
    return String(value)
  }
  if (value === 1000000) {
    output = value / million
    return `${output.toFixed(1)}M`
  }
  if (value >= thousand && value <= million) {
    output = value / thousand
    return `${output.toFixed(1)}K`
  }

  if (value >= million && value <= billion) {
    output = value / million
    return `${output.toFixed(1)}M`
  }

  if (value >= billion && value <= trillion) {
    output = value / billion
    return `${output.toFixed(1)}B`
  } else {
    return `${output.toFixed(1)}T`
  }
})

Vue.filter('dateFormat', function (date) {
  if (!date) return 'NA'

  var d = new Date(date)
  // var formattedDate = `${d.toLocaleDateString()}`
  var formattedDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
  return formattedDate
})

Vue.filter('platform', function (value) {
  if (!value) return 'NA'

  if (value) {
    const filtered = constantData.platformList.filter(row => row.value === value)
    if (filtered.length) {
      return filtered[0].label
    } else {
      return '-'
    }
  }
})

Vue.filter('campaignType', function (value) {
  if (!value) return 'NA'

  if (value) {
    const filtered = constantData.campaignTypeList.filter(row => row.value === value)
    if (filtered.length) {
      return filtered[0].label
    } else {
      return '-'
    }
  }
})

Vue.filter('youtube', function (value) {
  if (!value) return 'NA'

  if (value) {
    const filtered = constantData.contentYoutube.filter(row => row.value === value)
    if (filtered.length) {
      return filtered[0].label
    } else {
      return '-'
    }
  }
})

Vue.filter('infDetail', function (value) {
  if (value === null) return 'NA'

  const filtered = constantData.infList.filter(row => row.value === value)
  return filtered[0].label
})

Vue.filter('ytcategory', function (value) {
  let newvalue = value.split(',')
  newvalue = newvalue.map(Number) //  string array to numeric array
  var newcat = []
  for (let i = 0; i < newvalue.length; i++) {
    var cat = constantData.ytCategory.filter(function (obj) {
      return obj.value === newvalue[i]
    }).map(function (obj) {
      return obj.label
    })
    newcat = newcat.concat(cat)
  }
  if (!newcat.length) {
    return 'NA'
  } else {
    return newcat.toString()
  }
})

Vue.filter('campaignStatus', function (value) {
  if (value === '' || value === null) return 'NA'

  const filtered = constantData.campaignStatus.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  } else {
    return '-'
  }
})

Vue.filter('instagram', function (value) {
  if (!value) return 'NA'

  if (value) {
    const filtered = constantData.contentInstagram.filter(row => row.value === value)
    if (filtered.length) {
      return filtered[0].label
    } else {
      return '-'
    }
  }
})

Vue.filter('verify', function (value) {
  if (value !== 0 && value !== 1) return 'NA'
  const filtered = constantData.instaVerifyList.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  }
})
Vue.filter('role', function (value) {
  if (value === null) return 'NA'
  const filtered = constantData.roleType.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  }
})

Vue.filter('page', function (value) {
  if (value !== 0 && value !== 1) return 'NA'
  const filtered = constantData.pageList.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  }
})

Vue.filter('accountType', function (value) {
  if (value !== 0 && value !== 1) {
    return 'NA'
  }
  const filtered = constantData.instaAccountTypeList.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  }
})
