<template>
  <div class="wrapper d-flex">
    <side-bar />
    <nav class="navbar d-flex align-items-center">
      <!-- <b-nav>
        <b-nav-item>Dashboard</b-nav-item>
        <b-nav-item>Influencer</b-nav-item>
        <b-nav-item>Brand</b-nav-item>
      </b-nav> -->

      <!-- <p>Hello Rohan, Good Morning!</p> -->

      <b-dropdown variant="outline-white" class="ml-auto" right>
        <template #button-content>
          <img :src="`https://ui-avatars.com/api/?background=4C62E3&color=fff&name=${name}`" width="30" />
          <span style="text-transform: capitalize;">{{ name }}</span>
        </template>
        <b-dropdown-item>
          <b-icon icon="person-circle"></b-icon>
          Account
        </b-dropdown-item>
        <b-dropdown-item>
          <b-icon icon="gear"></b-icon>
          Settings
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="logout">
          <b-icon icon="box-arrow-in-right"></b-icon>
          Logout
        </b-dropdown-item>
      </b-dropdown>

    </nav>
    <div class="content flex-grow-1">
      <div class="page-view">
        <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from './SideBar.vue'

export default {
  components: { SideBar },
  data () {
    return {
      name: window.localStorage.getItem('user-name')
    }
  },
  methods: {
    logout () {
      window.localStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
  .content {
    /* width: 100%; */
    width: calc(100% - 320px);
    margin: 90px 20px 50px 300px;
  }

  /* header nav */
  .navbar {
    -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background: #ffffff;
    position: fixed;
    width: calc(100% - 320px);
    z-index: 12;
    left: 300px;
    top: 15px;
    border-radius: 6px;
  }
  .navbar:before {
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    left:0;
    height: 15px;
    width: 100%;
    background: #f8f8f8;
    opacity:0.90;
  }
  .navbar img {
    border-radius: 50%;
    margin: -3px 5px 0 0;
  }
  p {
    margin: 0;
  }

  /* route fade-transform */
  .fade-transform-leave-active,
  .fade-transform-enter-active {
    transition: all 0.3s;
  }
  .fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
  }
  .fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
</style>
