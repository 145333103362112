<template>
  <div class="sidebar">
    <router-link class="logo" to="/" >
      <img src="@/assets/images/soocel-logo.png" width="140" />
      <span>Admin</span>
    </router-link>

    <b-list-group class="navbar-nav" v-if="rights.menu.length">

      <b-list-group-item to="/dashboard">
        <b-icon icon="grid"></b-icon>
        <span>Dashboard</span>
      </b-list-group-item>

      <div v-for="(item, index) in sideBar" :key="item.title">

        <b-list-group-item v-if="rights.menu.length && rights.menu.includes(item[0].access)" :to="item[0].path" :active="currentIndex === index" v-b-toggle="`collapse-${index}`" :class="{'has-submenu': item[0].subs}">
          <b-icon :icon="item[0].icon"></b-icon>
          <span>{{item[0].title}}</span>
        </b-list-group-item>

        <b-collapse :id="`collapse-${index}`" :visible="currentIndex === index" v-if="item[0].subs && rights.menu.includes(item[0].access)">
          <div v-for="subItem in item[0].subs" :key="subItem.title">
            <b-nav-item v-if="rights.submenu.length && rights.submenu.includes(subItem.access)" :to="subItem.path" exact-active-class="active">
              <b-nav-text class="p-0">
                <b-icon icon="circle"></b-icon>
                {{subItem.title}}
              </b-nav-text>
            </b-nav-item>
          </div>
        </b-collapse>
      </div>

    </b-list-group>

  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      rights: JSON.parse(window.localStorage.getItem('user-rights')),
      collapse: false,
      currentIndex: 0,
      sideBar: [
        [
          {
            icon: 'grid',
            path: '/dashboard',
            title: 'Dashboard',
            access: 1000
          }
        ],
        [
          {
            icon: 'person',
            title: 'Influencer',
            access: 2000,
            subs: [
              {
                path: '/influencer/list',
                title: 'List',
                access: 2001
              },
              {
                path: '/influencer/youtube',
                title: 'YouTube',
                access: 2002
              },
              {
                path: '/influencer/instagram',
                title: 'Instagram',
                access: 2003
              },
              {
                path: '/influencer/mediator',
                title: 'Mediator',
                access: 2004
              },
              {
                path: '/influencer/agency',
                title: 'Agency',
                access: 2005
              }
            ]
          }
        ],
        [
          {
            icon: 'briefcase',
            title: 'Brand',
            access: 3000,
            subs: [
              {
                path: '/brand/list',
                title: 'Brand List',
                access: 3001
              }
            ]
          }
        ],
        [
          {
            icon: 'card-checklist',
            title: 'Campaign',
            access: 4000,
            subs: [
              {
                path: '/campaign/list',
                title: 'Campaign List',
                access: 4001
              }
            ]
          }
        ],
        [
          {
            icon: 'sliders',
            path: '/system/users',
            title: 'System Console',
            access: 5000
          }
        ],
        [
          {
            icon: 'bell',
            path: '/notifications/list',
            title: 'Notifications',
            access: 5000
          }
        ],
        [
          {
            icon: 'link',
            path: '/shorturl/list',
            title: 'Short Url',
            access: 5000
          }
        ],
        [
          {
            icon: 'envelope',
            path: '/influencer-management/leads',
            title: 'Leads',
            access: 5000
          }
        ]
      ]
    }
  },
  watch: {
    $route (to, from) {
      this.getIndex()
    }
  },
  mounted () {
    this.getIndex()
  },
  methods: {
    getIndex () {
      const path = this.$route.path
      for (let i = 0; i < this.sideBar.length; i++) {
        const item = JSON.stringify(this.sideBar[i])
        if (item.indexOf(path) >= 0) {
          this.currentIndex = i
          break
        }
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
  .sidebar {
    background: #ffffff;
    box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
    min-height: 100vh;
    margin: 0;
    width: 270px !important;
    padding: 20px 15px;
    position: fixed;
  }
  a.logo {
    margin: 0 auto;
  }
  .logo img {
    width: 140px;
  }
  .logo span {
    color: #625f6e;
    font-size: 12px;
    margin: 0 0 0 10px;
    position: relative;
    top: 5px;
  }
  .b-icon.bi {
    position: relative;
    margin: 0 10px 0 0;
    font-size: 18px;
    top: 1px;
  }
  .navbar-nav {
    margin: 30px 0 0;
  }
  .navbar-nav .list-group-item.has-submenu:after {
    content: "";
    height: 1.1rem;
    width: 1.1rem;
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 10px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 1.1rem;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navbar-nav .list-group-item.not-collapsed:after {
    transform: rotate(90deg);
  }
  .navbar-nav .list-group-item,
  .navbar-nav li.nav-item a {
    border: none;
    padding: 10px 15px 10px 0;
    color: #625f6e;
    line-height: 1.45;
    background: transparent;
  }
  .navbar-nav li.nav-item a.active,
  .navbar-nav .list-group-item.active,
  .navbar-nav li.nav-item a.router-link-active {
    color: #4c62e3;
  }
  .navbar-nav li.nav-item a:hover > *,
  .navbar-nav .list-group-item:hover > * {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
    color: #4c62e3;
  }
  .navbar-nav li.nav-item a:hover > *,
  .navbar-nav li.nav-item a > *,
  .navbar-nav .list-group-item:hover > *,
  .navbar-nav .list-group-item > * {
    -webkit-transition: -webkit-transform 0.25s ease;
    transition: transform 0.25s ease;
    display: inline-block;
  }
  /* .navbar-nav > li.active > a {
      background: linear-gradient(118deg,#7367f0,rgba(115,103,240,.7));
    } */
  .collapse {
    padding: 0 0 0 25px;
  }
  .collapse .b-icon.bi {
    position: relative;
    margin: 0 5px 0 0;
    font-size: 10px;
    top: -7px;
  }
</style>
