import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: Layout,
    children: [{
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/Home.vue')
    }]
  },
  {
    path: '/system',
    component: Layout,
    children: [{
      path: 'users',
      name: 'userlist',
      component: () => import('@/views/SystemConsole.vue')
    }]
  },
  {
    path: '/influencer-management',
    component: Layout,
    children: [{
      path: 'leads',
      name: 'soocelLeads',
      component: () => import('@/views/Leads.vue')
    }]
  },
  {
    path: '/influencer',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'influencerlist',
        component: () => import('@/views/influencers/List.vue')
      },
      {
        path: 'youtube',
        name: 'youtube',
        component: () => import('@/views/influencers/Youtube.vue')
      },
      {
        path: 'instagram',
        name: 'instagram',
        component: () => import('@/views/influencers/Instagram.vue')
      },
      {
        path: 'mediator',
        name: 'mediator',
        component: () => import('@/views/influencers/Mediator.vue')
      },
      {
        path: 'agency',
        name: 'agency',
        component: () => import('@/views/influencers/Agency.vue')
      }
    ]
  },
  {
    path: '/brand',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'brandlist',
        component: () => import('@/views/brand/Index.vue')
      }
    ]
  },
  {
    path: '/campaign',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'campaignlist',
        component: () => import('@/views/campaign/Index.vue')
      },
      {
        path: '/campaign/details/:campaignId/:platform',
        name: 'campaignDetails',
        component: () => import('@/views/campaign/Details.vue')
      }
    ]
  },
  {
    path: '/shorturl',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'urllist',
        component: () => import('@/views/shorturl/Index.vue')
      }
    ]
  },
  {
    path: '/notifications',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'notificationslist',
        component: () => import('@/views/notification/Index.vue')
      }
    ]
  },
  {
    path: '/user/login',
    name: 'login',
    component: () => import('@/views/user/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  const publicPages = ['login']
  const isPublicRoute = publicPages.includes(to.name)
  const loggedIn = window.localStorage.getItem('user-token')

  if (isPublicRoute) {
    if (!loggedIn) {
      next()
    } else {
      next('/dashboard')
    }
  } else {
    if (loggedIn) {
      next()
    } else {
      next(`/user/login?redirect=${from.path}`)
    }
  }
})

export default router
